<template>
  <div class="page">
    <b-card>
      <div
        class="custom-search d-flex"
        style="justify-content:space-between"
      >
        <b-button
          variant="outline-primary"
          class="mb-2"
          @click="$router.push({name: 'Create Client'})"
        >
          <feather-icon icon="PlusIcon" />
          Add New Account
        </b-button>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="fields"
        :rows="users"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        class="superAdminAccountTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.profile_image"
              class="mr-1"
            />
            <span class="text-nowrap">
              <router-link :to="'/accounts/' + props.row.id">
                {{ props.row.name }}
              </router-link>
            </span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'active'">
            <div>
              <feather-icon
                v-if="props.row.active !== 1"
                icon="XIcon"
                size="20"
                class="redIcon"
              />
              <feather-icon
                v-else
                icon="CheckIcon"
                size="20"
                class="greenIcon"
              />
            </div>
          </span>
          <span v-else-if="props.column.field === 'payment'">
            <div>
              <feather-icon
                v-if="props.row.payment !== 1"
                icon="XIcon"
                size="20"
                class="redIcon"
              />
              <feather-icon
                v-else
                icon="CheckIcon"
                size="20"
                class="greenIcon"
              />
            </div>
          </span>

          <!-- Column: Updated At -->
          <span
            v-else-if="props.column.field === 'updated'"
            class="updatedSpan"
          >
            <div v-if="props.row.updated">
              <span>{{ new Date(props.row.updated).toLocaleString('en-CA') }}</span>
            </div>

            <div
              v-else
              class="text-center"
            >
              <feather-icon
                icon="MinusIcon"
                size="16"
                class="m-auto"
              />
            </div>
          </span>

          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item :to="'/accounts/' + props.row.id">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item>
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination, BAvatar,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

export default {
  components: {
    BCard, BButton, BDropdown, BDropdownItem, VueGoodTable, BFormGroup, BFormInput, BFormSelect, BPagination, BAvatar,
  },
  data() {
    return {
      pageLength: 10,
      searchTerm: '',
      // fields: ['id', 'name', 'accountType', 'plan', 'active', 'payment', 'email', 'phone', 'website', 'updated', 'action'],
      fields: [
        {
          label: 'id',
          field: 'id',
          sortable: false,
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Account Type',
          field: 'accountType',
        },
        // {
        //   label: 'Plan',
        //   field: 'plan',
        // },
        {
          label: 'Active',
          field: 'active',
          sortable: false,
        },
        {
          label: 'Payment',
          field: 'payment',
          sortable: false,
        },
        {
          label: 'Email',
          field: 'email',
          sortable: false,
        },
        {
          label: 'Phone',
          field: 'phone',
          sortable: false,
        },
        {
          label: 'Website',
          field: 'website',
          sortable: false,
        },
        {
          label: 'Updated At',
          field: 'updated',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      users: [],
    }
  },
  created() {
    this.$store.dispatch('superadmin/getAccounts').then(response => {
      this.users = response.data.map(r => {
        return {
          id: r.id,
          name: r.name,
          accountType: this.capitalizeFirstLetter(r.account_type),
          plan: '',
          active: r.is_init,
          payment: r.card_last_four ? 1 : 0,
          email: r.primary_email,
          phone: r.primary_phone,
          website: r.website,
          profile_image: r.profile_image,
          updated: r.updated_at,
        }
      })
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>

<style>
.superAdminAccountTable tr td{
  vertical-align: middle !important;
}
</style>
